<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "index",

	}
</script>

<style lang="less">
	* {  margin: 0; padding: 0; border: none; box-sizing: border-box;}
	html, body {width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; font-family: "Arial";}
	ul, ol, li{list-style-type:none;}
	a {text-decoration: none;}
	a:link, a:visited, a:hover, a:active {color: #544f4f;}
	a, img, input, textarea, button, select {outline: none;}
	select { appearance:none; -moz-appearance:none; -webkit-appearance:none;  }

	html, body, #app{
		width: 100%;
		height: 100%;
		margin: 0 auto;
		overflow: hidden;
	}

</style>
