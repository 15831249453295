// 获取assets中的资源
import Vue from "vue";

export function _import(type, name) {
	if(name) {
		return require(`@/assets/${type}/${name}`);
	}
	return require(`@/assets/${type}`);
}


Vue.prototype.$import = _import;
