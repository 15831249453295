import Vue from 'vue'
import http from './http'
import {Toast} from 'vant'

const {get, post, postFormData} = http;
let config = JSON.parse(localStorage.dynamicConfig);
let baseUrl = process.env.NODE_ENV === "development" ? 'vapi/' : config.baseUrl;
baseUrl = baseUrl + 'interface/api/';



/*轮播图*/
export function carousel(data) {
	return get(baseUrl + 'carousel', data)
}

/*首页*/
export function home(data) {
	return get(baseUrl + 'home', data)
}

/*新闻 列表*/
export function contentList(data) {
	return get(baseUrl + 'contentList', data)
}

/*新闻 详情*/
export function content(data) {
	return get(baseUrl + 'content', data)
}

/*产品 列表*/
export function productList(data) {
	return get(baseUrl + 'productList', data)
}

/*产品 详情*/
export function product(data) {
	return get(baseUrl + 'product', data)
}

/*园区动态*/
export function imgList(data) {
	return get(baseUrl + 'imgList', data)
}







Vue.prototype.$title = config.title;
Vue.prototype.$api = {
	carousel,
	home,
	contentList,
	content,
	productList,
	product,
	imgList
}