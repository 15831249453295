import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		redirect: '/index',
		component: () => import('../views/index'),
		children: [
			{
				path: '/index',
				name: 'home',
				component: () => import('../views/home')
			},
			{
				path: '/product',
				name: 'product',
				component: () => import('../views/product')
			},
			{
				path: '/product/:id',
				name: 'productDtl',
				component: () => import('../views/product/dtl')
			},
			{
				path: '/news',
				name: 'news',
				component: () => import('../views/news')
			},
			{
				path: '/news/:id',
				name: 'newsDtl',
				component: () => import('../views/news/dtl')
			},
			{
				path: '/scenery',
				name: 'scenery',
				component: () => import('../views/scenery')
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('../views/about')
			}
		]
	}
]

//重写push，避免冗余导航报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
