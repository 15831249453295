import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/http'
import './utils/api'
import './utils/theme'
import './utils/vant'
import {reSize} from './utils/resize'

reSize();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
