const state = {
	title: ""
}

const mutations = {
	SET_TITLE: (state, val) => {
		console.log(val)
		state.title = val;
	}
}

const actions = {
	setTitle({commit}, val) {
		commit('SET_TITLE', val)
	}
}

export default {
	state,
	mutations,
	actions
}
