import Vue from 'vue';


import {
	Toast,
	Loading,
	Lazyload,
	Swipe,
	SwipeItem,
	Image as VanImage,
	Icon,
	Pagination,
	ImagePreview
} from 'vant'

Vue.use(Toast)
Vue.use(Loading)
Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VanImage)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(ImagePreview)


Vue.prototype.$toast = Toast;
Vue.prototype.$loading = Toast.loading;
Vue.prototype.$imagePreview = ImagePreview;