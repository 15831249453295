export function IsPC() {
	let userAgentInfo = navigator.userAgent;
	let Agents = [
		"Android",
		"iPhone",
		"SymbianOS",
		"Windows Phone",
		"iPad",
		"iPod"
	];

	let flag = true;
	for (let v = 0; v < Agents.length; v++) {
		if (userAgentInfo.indexOf(Agents[v]) > 0) {
			flag = false;
			break;
		}
	}

	return flag; // true为PC端，false为手机端
}

export function reSize() {
	onReSize();
	window.onresize = function() {
		onReSize();
	}
}

function onReSize() {
	let deviceWidth = document.documentElement.clientWidth;

	if( IsPC() ) {
		let devicePixel = window.devicePixelRatio;
		if(deviceWidth <= 800) {
			deviceWidth = 2000;
		}else {
			deviceWidth = 1000;
		}
		document.documentElement.style.fontSize = (deviceWidth / 100 / devicePixel) + 'px';
	}else {
		if(deviceWidth >= 750) {
			deviceWidth = 750;
		}
		if(deviceWidth <= 320) {
			deviceWidth = 320;
		}
		document.documentElement.style.fontSize = (deviceWidth / 37.5) + 'px';
	}
}